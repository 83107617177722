<template>
  <div class="buyOrder">
    <el-row>
      <el-col :span="4">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="今日订单" name="first"></el-tab-pane>
          <el-tab-pane label="历史订单" name="second"></el-tab-pane>
        </el-tabs>
        <el-col
          :span="24"
          v-for="(item,index) in content"
          :key="index"
          class="orderItem"
          :class="{'channgeColor':index==square}"
        >
          <el-col :span="24">
            <div @click="choose(index,item)">
              <div>
                <p class="name">{{ item.name }}</p>
                <p class="createTime">{{ item.createTimeFormat }}</p>
                <p class="status">
                  <i class="leftAngle"></i>
                  <span class="statusText">{{ item.status }}</span>
                </p>
                <p
                  v-if="item.status == '已报价'"
                  class="cancelOrder"
                  @click.prevent="cancelOrder(item)"
                >撤单</p>
              </div>
            </div>
          </el-col>
        </el-col>
        <el-col v-if="content.length === 0">
          <p class="noOrder">暂无订单</p>
        </el-col>
      </el-col>
      <el-col :span="20">
        <el-steps :active="active" v-if="false">
          <el-step title="提交意向 2017/12/05 10:19:09"></el-step>
          <el-step title="卖家确定订单  已等待15分16秒"></el-step>
          <el-step title="交易完成"></el-step>
        </el-steps>
        <div class="green" v-if="false">
          <el-col :span="7">卖家已确认！由于行情波动频繁，请及时与卖家联系</el-col>
          <el-col :span="7" :offset="10">确定时间：2017/12/22 09：50</el-col>
        </div>
        <el-row class="orderContainer">
          <el-col :span="24" class="orderDetails">
            <el-col :span="7">
              <p class="orderNumber">订单号： {{ detail.id }}</p>
            </el-col>
            <el-col :span="17"></el-col>
          </el-col>
          <el-col :span="24" class="productDetails">
            <el-col :span="7">商品明细</el-col>
          </el-col>
          <el-col :span="24">
            <div class="productDetailsTitle">
              <div class="titleItem">
                <p>品名</p>
              </div>
              <div class="titleItem">
                <p>单价</p>
              </div>
              <div class="titleItem">
                <p>计量单位</p>
              </div>
              <div class="titleItem">
                <p>产地</p>
              </div>
              <div class="titleItem">
                <p>仓库</p>
              </div>
            </div>
            <div class="productDetailsContainer">
              <div class="listItem">
                <p>{{detail.name}}</p>
              </div>
              <div class="listItem">
                <p>{{detail.price}}</p>
              </div>
              <div class="listItem">
                <p>{{detail.meterageUnit}}</p>
              </div>
              <div class="listItem">
                <p>{{detail.origin}}</p>
              </div>
              <div class="listItem">
                <p>{{detail.warehouse}}</p>
              </div>
            </div>
          </el-col>
          <el-col :span="24" class="buyDetails">
            <el-col :span="7">买家信息</el-col>
          </el-col>
          <el-col :span="24" class="buyDetailsContainer">
            <div class="buyItem" v-for="item in buyList" :key="item.id">
              <p>{{ item.name }}</p>
              <p>{{ item.price }}</p>
              <p>{{ item.meterageUnit }}</p>
              <p>{{ item.firmName }}</p>
              <p>{{ item.cellphone }}</p>
            </div>
          </el-col>
          <el-col :span="24" v-if="buyList.length !== 0" class="orderPagination">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="current"
              :page-size="100"
              layout="total, prev, pager, next"
              :total="totalPage"
            ></el-pagination>
          </el-col>
          <el-col :span="24" class="sellDetails">
            <el-col :span="7">卖家信息</el-col>
          </el-col>
          <el-col :span="24">
            <div class="sellDetailsContainer">
              <el-col :span="8">公司名称：{{detail.enterpriseName}}</el-col>
              <el-col :span="8">联系人：{{detail.firmName}}</el-col>
              <el-col :span="8">联系方式：{{detail.cellphone}}</el-col>
              <el-col :span="8">开户银行：{{detail.bankName}}</el-col>
              <el-col :span="8">开户名：{{detail.bankFirmname}}</el-col>
              <el-col :span="8">卡号：{{detail.bankNumber}}</el-col>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 1,
      isActive: true,
      today: [],
      history: [],
      content: [],
      activeName: "first",
      square: null,
      detail: {},
      buyList: [],
      current: 1,
      totalPage: null,
      currentOrderId: null
    };
  },
  mounted() {
    this.param_queryMyOrderOverview();
  },
  methods: {
    handleCurrentChange(val) {
      this.current = val;
      this.param_queryQuotationSheetDetails(this.currentOrderId);
    },
    param_queryMyOrderOverview() {
      protocolFwd.param_queryMyOrderOverview.param.page = 0;
      protocolFwd.param_queryMyOrderOverview.param.size = 20;
      http.postFront(protocolFwd.param_queryMyOrderOverview).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.today = value.today;
          this.history = value.history;
          this.content = value.today;
          for (let i = 0; i < this.content.length; i++) {
            this.content[i].createTimeFormat = global.util.DateFormate(
              this.content[i].createTime
            );
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    handleClick() {
      this.square = 0;
      if (this.activeName == "first") {
        for (let i = 0; i < this.content.length; i++) {
          this.content[i].createTimeFormat = global.util.DateFormate(
            this.content[i].createTime
          );
        }
        this.content = this.today;
      } else {
        for (let i = 0; i < this.content.length; i++) {
          this.content[i].createTimeFormat = global.util.DateFormate(
            this.content[i].createTime
          );
        }
        this.content = this.history;
      }
    },
    choose(index, item) {
      this.square = index;
      this.currentOrderId = item.id;
      this.param_queryMyOrderDetails(item.id);
      this.param_queryQuotationSheetDetails(item.offId);
    },
    param_queryMyOrderDetails(id) {
      protocolFwd.param_queryMyOrderDetails.param.orderId = id;
      http.postFront(protocolFwd.param_queryMyOrderDetails).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.detail = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    param_queryQuotationSheetDetails(id) {
      protocolFwd.param_queryQuotationSheetDetails.param.offId = id;
      protocolFwd.param_queryQuotationSheetDetails.param.orderId = null;
      protocolFwd.param_queryQuotationSheetDetails.param.page =
        this.current - 1;
      protocolFwd.param_queryQuotationSheetDetails.param.size = 10;
      protocolFwd.param_queryQuotationSheetDetails.param.sort = [
        { direction: "DESC", property: "id" }
      ];
      http
        .postFront(protocolFwd.param_queryQuotationSheetDetails)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.buyList = value.content;
            this.totalPage = value.totalElements;
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    cancelOrder(item) {
      this.$confirm("确认撤销报价单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          protocolFwd.param_changeOfferOrder.param.status = 3;
          protocolFwd.param_changeOfferOrder.param.offerId = item.offId;
          http.postFront(protocolFwd.param_changeOfferOrder).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.param_queryMyOrderOverview();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        })
        .catch(() => {});
    }
  }
};
</script>
<style scoped>
.buyOrder {
  padding: 0 20px 0 0;
}
.buyOrder .orderDetails {
  padding: 0 10px;
  line-height: 35px;
  background-color: #f2f2f2;
}
.buyOrder .orderDetails .orderDate {
  color: #666666;
  text-align: right;
}
.buyOrder .orderContainer {
  padding-left: 20px;
}
.buyOrder .productDetails {
  margin-top: 2px;
  padding: 0 10px;
  line-height: 35px;
  font-weight: 700;
  background-color: #f2f2f2;
}
.productDetailsTitle {
  line-height: 35px;
  overflow: hidden;
}
.productDetailsTitle .titleItem {
  float: left;
  width: 20%;
  text-align: center;
}
.productDetailsContainer {
  line-height: 35px;
  overflow: hidden;
}
.productDetailsContainer .listItem {
  float: left;
  width: 20%;
  color: #666666;
  text-align: center;
}
.buyOrder .buyDetails {
  padding: 0 10px;
  line-height: 35px;
  font-weight: 700;
  background-color: #f2f2f2;
}
.buyOrder .buyDetailsContainer .buyItem {
  line-height: 35px;
  text-align: center;
  overflow: hidden;
}
.buyOrder .buyDetailsContainer .buyItem p {
  float: left;
  width: 20%;
}
.buyOrder .sellDetails {
  margin-top: 2px;
  padding: 0 10px;
  line-height: 35px;
  font-weight: 700;
  background-color: #f2f2f2;
}
.buyOrder .sellDetailsContainer {
  padding: 0 10px;
  line-height: 35px;
}
.buyOrder .orderItem {
  position: relative;
  padding: 5px;
  margin-bottom: 10px;
  line-height: 22px;
  cursor: pointer;
  border: 1px solid #dcdcdc;
  overflow: hidden;
}
.buyOrder .orderItem .createTime {
  color: #666666;
}
.buyOrder .orderItem .status {
  position: absolute;
  top: 10px;
  right: 0;
  line-height: 20px;
}
.buyOrder .orderItem .status .leftAngle {
  float: left;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: transparent #f4a83c transparent transparent;
}
.buyOrder .orderItem .status .statusText {
  float: left;
  display: block;
  padding: 0 8px 0 5px;
  line-height: 20px;
  font-size: 12px;
  color: #ffffff;
  background-color: #f4a83c;
}
.buyOrder .orderItem.channgeColor {
  border: 1px solid orange;
}
.buyOrder .orderItem .status {
  position: absolute;
  top: 5px;
  right: 0;
}
.buyOrder .orderItem .cancelOrder {
  float: right;
  color: #666666;
}
.buyOrder .noOrder {
  margin: 10px 0;
  text-align: center;
  color: #666666;
}
.active {
  border: 1px solid orange;
}
.green {
  background: rgba(98, 158, 77, 1);
  width: 100%;
  height: 20px;
  text-align: center;
  color: white;
}
.xuanzhuan {
  transform: rotate(-20deg);
  -ms-transform: rotate(-20deg); /* IE 9 */
  -moz-transform: rotate(-20deg); /* Firefox */
  -webkit-transform: rotate(-20deg); /* Safari 和 Chrome */
  -o-transform: rotate(-20deg); /* Opera */
  width: 50%;
  height: 15px;
  line-height: 15px;
  text-align: center;
  border: 1px solid orange;
  margin-left: 10px;
  font-size: 7px;
  margin-top: 10px;
}
.gray {
  background: rgba(215, 215, 215, 1);
  width: 100%;
  height: 20px;
  text-align: center;
  color: white;
}
.channgeColor {
  border: 1px solid orange;
}
.orderPagination {
  text-align: right;
}
</style>
